var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("First name")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.first_name))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Last name")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.last_name))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Email")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.email))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Phone number")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.user.phone))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Permissions")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c("gov-list", [
                    _c("li", [
                      _vm._v(
                        "Super admin: " + _vm._s(_vm.superAdmin ? "Yes" : "No")
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Global admin: " +
                          _vm._s(_vm.globalAdmin ? "Yes" : "No")
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "Content admin: " +
                          _vm._s(_vm.contentAdmin ? "Yes" : "No")
                      )
                    ]),
                    _c(
                      "li",
                      [
                        _vm.organisationAdmin.length === 0
                          ? [_vm._v("Organisation admin: No")]
                          : _c(
                              "gov-details",
                              {
                                staticClass: "no-margin",
                                attrs: { summary: "Organisation admin: Yes" }
                              },
                              _vm._l(_vm.organisationAdmin, function(
                                role,
                                key
                              ) {
                                return _c(
                                  "div",
                                  { key: key },
                                  [
                                    _c("gov-link", {
                                      attrs: {
                                        to: {
                                          name: "organisations-show",
                                          params: {
                                            organisation: role.organisation_id
                                          }
                                        }
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          role.organisation.name
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ],
                      2
                    ),
                    _c(
                      "li",
                      [
                        _vm.serviceAdmin.length === 0
                          ? [_vm._v("Service admin: No")]
                          : _c(
                              "gov-details",
                              {
                                staticClass: "no-margin",
                                attrs: { summary: "Service admin: Yes" }
                              },
                              _vm._l(_vm.serviceAdmin, function(role, key) {
                                return _c(
                                  "div",
                                  { key: key },
                                  [
                                    _c("gov-link", {
                                      attrs: {
                                        to: {
                                          name: "services-show",
                                          params: { service: role.service_id }
                                        }
                                      },
                                      domProps: {
                                        textContent: _vm._s(role.service.name)
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ],
                      2
                    ),
                    _c(
                      "li",
                      [
                        _vm.serviceWorker.length === 0
                          ? [_vm._v("Service worker: No")]
                          : _c(
                              "gov-details",
                              {
                                staticClass: "no-margin",
                                attrs: { summary: "Service worker: Yes" }
                              },
                              _vm._l(_vm.serviceWorker, function(role, key) {
                                return _c(
                                  "div",
                                  { key: key },
                                  [
                                    _c("gov-link", {
                                      attrs: {
                                        to: {
                                          name: "services-show",
                                          params: { service: role.service_id }
                                        }
                                      },
                                      domProps: {
                                        textContent: _vm._s(role.service.name)
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ],
                      2
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }